@import "./vendor/styles/theme-teldat.scss";
.mh-50 {
  max-height: 500px !important;
}
.vh-75 {
  height: 75vh !important;
}
.obj-cus-h-35 {
  height: 35rem !important;
}
.vw-90 {
  max-width: 90vw !important;
}
.vw-60 {
  max-width: 60vw !important;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}

.border-py-0 .MuiAutocomplete-inputRoot,
.input-py-0 input[type="text"] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.modal-shadow-lg .modal-content {
  box-shadow: 20px 13px 20px 20px #181c212d;
  height: 49em;
}
.d-flex-navbar a {
  display: flex;
  align-items: center;
}
.avatar-small {
  width: 32px !important;
  height: 32px !important;
}
.checkbox-cursor {
  label,
  input[type="checkbox"] {
    cursor: pointer;
  }
}
.custom-file-label::after {
  background: $primary-color;
  color: white;
  cursor: pointer;
}
.modal-header .close {
  background-color: $light-gray;
  font-weight: bold;
  right: 0rem;
  top: 0.8em;
  width: 2em;
  padding-bottom: 3px;
}
.modal-header.info {
  justify-content: flex-start;
}

.bg-light-grey {
  background-color: #f4f4f4;
}
.text-cus-heading {
  font-size: 1.2rem;
}

.text-sub-title {
  font-size: 0.85rem;
  font-weight: 700;
  width: 150px;
}

.object-explorer {
  .list-group-item.active,
  .list-group-item.active:hover,
  .list-group-item.active:focus,
  .nav-pills .nav-link.active,
  .nav-pills .nav-link.active:hover,
  .nav-pills .nav-link.active:focus {
    border-color: #e3e3e3;
    background-color: #e3e3e3;
    color: #394c65;
    font-weight: 500;
  }
  .nav-pills .nav-link {
    border-radius: 0px;
  }
  .custom-headar-pad {
    padding-top: 0.57rem;
    padding-bottom: 0.57rem;
  }
}

.modal-content {
  border-radius: 0px;
}

.card,
.card-header {
  border-radius: 0rem !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  box-shadow: none;
  outline: none;
}

.table th {
  font-weight: 700 !important;
}
.modal-open .tooltip {
  z-index: 9999;
}
.obj-where-used {
  max-height: 10rem;
  overflow: auto;
}

.highlighted {
  background-color: #FAC05E!important;
  color: #394c65;
}

.highlighted:hover {
  background-color: #FAC05E!important;
  color: #394c65;
}

.highlighted.active {
  background-color: #FAC05E!important;
  color: #394c65;
}

.highlighted.active:hover {
  background-color: #FAC05E!important;
  color: #394c65;
}

.scroll-table {
  overflow-y: auto;
  height: 600px;
  th {
    position: sticky;
    top: -1px;
    background-color: white;
  }
}

.z-index9 {
  z-index: 9;
}

.info-button {
  border-radius: 999px;
  cursor: default!important;
}

.ct-text {
  white-space : pre-line !important;
}