@import '../../vendor/styles/theme-teldat.scss';

.tab-content{
  height: calc(100% - 45px);
}
.tab-pane{
  height: 100%;
}
.custom-tabs{
  margin-bottom: 0.5em;
  height: 100%;
  overflow-y: auto;
  .tops-bar {
    height: 0.5rem;
    border-radius: 0;
  }
}
.calendar-form-row{
  width: 50% !important;
  &.pad-r{
    padding-right: 0.25rem !important;
  }
  &.pad-l{
    padding-left: 0.25rem !important;
  }
}

.table-fixed {
  overflow-y: auto;
  height: calc(100% - 80px);
}

.table-fixed thead th{
  background-color: white;
}

.nav {
  justify-content: space-around !important;
}

.nav-tabs {
  border-bottom: none !important;

  .nav-link {
    border: none !important;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    font-weight: 700;
    border-radius:0px !important;
    border-bottom-width: 4px !important;
    border-bottom-style: solid !important;
    border-bottom-color: $primary-color !important;
  }
}

.blade-custom-td {
  cursor: pointer;
  text-decoration-line: underline;
}

.custom-form-group {
  margin-bottom: 0 !important;
}

.list-square-type {
  list-style-type: square;
}

.list-disc-type {
  list-style-type: disc;
}

.custom-border{
  border-bottom-color: $primary-color !important;
  border-bottom: 2px solid;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
}

.h-700 {
  height: 700px;
}