@import '../../../src/vendor/styles/theme-teldat.scss';
$enabled-color: #00a99d;
$disabled-color: #b97c22;
.policy-tbr-disbaled {
  background-color: lightgrey !important;
}
.picker-plus-icon {
  padding: 2px 3px;
}
.drag-line {
  border-color: $primary-color !important;
}
.w-20 {
  width: 20%;
}
.loader-wrapper {
  width: 50%;
  background-color: $pure-white;
  box-shadow: 0 0 10px $light-gray;
  margin: auto;
  padding: 40px;
}
.policy-progress-bar {
  width: 90%;
  height: 20px !important;
  font-size: 14px !important;
  background: $white1 !important;
  font-weight: bold;
  border-radius: 0 !important;
}
.policiesTbody {
  tr {
    td {
      vertical-align: middle;
    }
    td:first-child {
      padding-left: 0;
      .style-border {
        display: table;
        height: 100%;
        min-width: 30px;
        text-align: center;
        div {
          display: table-cell;
          vertical-align: middle;
        }
      }
      .move-position {
        cursor: grab !important;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    td.enabled-td .style-border {
      border-left: 3px solid $enabled-color !important;
      .move-position {
        color: $enabled-color;
      }
    }
    td.disabled-td .style-border {
      border-left: 3px solid $disabled-color !important;
      .move-position {
        color: $disabled-color;
      }
    }
    td.last-policy .style-border {
      border-left: 3px solid lightgrey !important;
    }
  }
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper .btn[disabled] {
  pointer-events: none;
}

.badge-medium {
  background-color: orange;
  color: white;
}

.badge-very-high {
  background-color: darkred;
  color: white;
}
.under-authentication {
  background-color: lightyellow;
}

.rules-section {
  background-color: #CFDCDD !important;
  text-align: center;
  height: 35px;
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "sans-serif";
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  font-weight: 600;
}